@media (min-height: 768px) {
    #quick-start {
      bottom: 26%;
    }
}

@media (min-height: 626px) and (max-height: 768px) {
    #quick-start {
      bottom: 20%;
    }
}

@media (max-height: 626px) {
    #quick-start {
      bottom: 15%;
    }
}

/* 阿里巴巴普惠体 3.0 Regular */
@font-face {
    font-family: alibaba-puhuiti;
    src: url('https://assets.mosiai.cn/fonts/AlibabaPuHuiTi-3-55-Regular.woff2') format('woff2');
    font-weight: 400;
}

/* 阿里巴巴普惠体 3.0 Medium */
@font-face {
    font-family: alibaba-puhuiti;
    src: url('https://assets.mosiai.cn/fonts/AlibabaPuHuiTi-3-65-Medium.woff2') format('woff2');
    font-weight: 500;
}

body::-webkit-scrollbar {
    width: 4px;
}

body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: thin;  /* Firefox */
}

.x2-btn {
    padding: 0 4px;
    color: #1677ff;
}
  
.x2-btn:hover {
    color: #69b1ff;
    cursor: pointer;
}

.line-clamp2 {
    overflow: hidden;
    text-overflow: ellipsis;
    /*display: -webkit-box;*/
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #999;
    font-size: 12px;
}

.response-quill .ql-container {
    height: 400px;
}

.response-quill .ql-container:not(.ql-disabled) {
    background: #FFF;
}

.response-quill .ql-editor p, .ql-editor p {
    font-size: 14px;

    margin-top: 0;
    margin-bottom: 1em;
    line-height: 150%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.doc-quill-editor .ql-container {
    min-height: 400px;
    background: #FFF;
}

.ant-tabs-content-holder {
    overflow: auto;
}

.spell-group-mng .ant-tabs-nav {
    margin-left: 0px;
    padding-left: 0px;
}

.spell-group-mng .ant-tabs-content-holder {
    padding: 24px;
    background-color: #fff;
    height: 100% !important;
}
.spell-group-mng .ant-tabs-tabpane {
    padding-left: 0px !important;
}

.editpwd-modal .ant-modal-content {
    padding: 0 !important;
}

.register-modal .ant-modal-content {
    padding: 0 !important;
}

.login-modal .ant-modal-content {
    padding: 0 !important;
}

.hidden-tabs-nav .ant-tabs-nav {
    display: none;
}
.hidden-tabs-nav .ant-tabs-content-holder {
    border-left: 0;
    padding-left: 0px !important;
}

.aigc-config-execute-tab .ant-tabs-nav {
    display: block;
    margin-left: 0px;
    margin-bottom: 12px !important;
}
.aigc-config-execute-tab .ant-tabs-content-holder {
    padding: 0;
}

.ant-table-placeholder {
    z-index: 999 !important;
}
